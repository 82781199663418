<template>
	<div>
		<form id="add-form" class="whiteBg">
			<div>
				<i class="icon-code wh05 mar-r2" style="background-size:80% auto"></i>
				<p>项目编号</p>
				<input type="text" placeholder="请输入" v-model="project.projectNum">
			</div>
			<div>
				<i class="icon-project wh05 mar-r2"></i>
				<p>项目名称</p>
				<input type="text" placeholder="请输入" v-model="project.projectName">
			</div>
			<div>
				<i class="icon-unit wh05 mar-r2"></i>
				<p>采购单位</p>
				<input type="text" placeholder="请输入" v-model="project.purchaseOrg">
			</div>

			<div>
				<i class="icon-time wh05 mar-r2"></i>
				<p>评审时间</p>
				<input type="text" placeholder="请输入" @focus="showTime = true" v-model="project.reviewDatetime">

			</div>
			<div>
				<i class="icon-addr wh05 mar-r2"></i>
				<p>评审地点</p>
				<input type="text" placeholder="请输入" v-model="project.reviewSite">
			</div>
			<div class="w-line" style="padding:0"></div>
			<div>
				<i class="icon-package wh05 mar-r2"></i>
				<p>包组编号</p>
				<input type="text" placeholder="请输入" v-model="project.packageNum">
			</div>
			<div>
				<i class="icon-name wh05 mar-r2"></i>
				<p>专家人数</p>
				<input type="number" placeholder="请输入" v-model="project.expertNum">
			</div>
			<div class="mar-t2 flex-between">
                <div>
                <i class="icon-profession wh05 mar-r2"></i>
                <p class="inline fw">评审专业</p>
                </div>
                <div>
                    <button class="addBtn f35" @click.prevent="onSelectMajors">添加</button>
                </div>
			</div>
            <div v-show="selectedMajors.length > 0">
                <p class="f35 red"  style="width: 100%">不指定人数表示不限制人数</p>
            </div>
            <div>
                <ul class="w-100">
                    <li class="flex-between" v-for="(item,index) in selectedMajors" style="margin-top: 0.2rem">
                        <span class="center van-ellipsis greenLi fw">
                            <i class="del" @click="removeChoosedMajor(item,index)"></i>
                            {{ item.fullName }}
                        </span>
                        <input
                            type="tel"
                            oninput="if(value < 0 || value =='-'){value = ''}"
                            placeholder="指定人数"
                            class="step"
                            v-model="item.num"
                            @blur="goView($event)"
                        >
                    </li>
                </ul>
            </div>

			<div class="w-line" style="padding:0;height:2.5rem"></div>
			<p class="whiteBg pad-row pad-col3 fixed-bottom center">
				<button class="btn fw mar-l" style="font-size:0.45rem" @click.prevent="next">抽取专家</button>
			</p>
		</form>
		<!-- 时间拾取器 -->
		<van-popup v-model="showTime" position="bottom">
			<van-datetime-picker
                :min-date="minDate"
                :value='defaultDtVal'
                type='datetime'
                title='选择评审日期'
                @cancel="showTime=false"
			    @confirm="onConfirmTime"
            >

			</van-datetime-picker>
		</van-popup>
	</div>
</template>
<script>
import {DatetimePicker,Stepper,Switch,Popup,Dialog } from 'vant'
export default {
	data() {
		return {
			showTime: false,
			minDate: new Date(),
			defaultDtVal: new Date(),
			project:{
				projectNum: 'Test-001',
				projectName: '测试项目',
				purchaseOrg: '测试采购单位',
				reviewDatetime: '',
				reviewSite: '测试评审地点',
				packageNum: '01',
				expertNum: '3',
			},
			selectedMajors:[],//选中的专业数组
		}
	},
	components: {
		[DatetimePicker.name]: DatetimePicker,
		[Stepper.name]:Stepper,
		[Switch.name]:Switch,
		[Popup.name]: Popup,
        [Dialog.name]: Dialog ,
	},

    created(){
        /** 清除Demo中的所有缓存 **/
        this.$ls.remove('major-demo-list')
        this.$ls.remove('demo-project')
        this.$ls.remove('rounds-demo')
        this.$ls.remove('confirm-expert-list-demo')
        this.onConfirmTime(new Date())
    },

	activated() {
		this.$emit('onActive', 1)
		/** 从缓存中获取选择的专业 */
        this.selectedMajors =  this.$ls.get('major-demo-list') || []
	},

	methods: {
		//选择时间
		onConfirmTime: function(e) {
			let y = e.getFullYear()
			let m = '0' + (e.getMonth()+1)
			let d = '0' + e.getDate()
			let h = '0' + e.getHours()
			let mi = '0' + e.getMinutes()
			let s = '0' + e.getSeconds()
			this.project.reviewDatetime = y +"-" + m.slice(-2) +"-" +d.slice(-2)+" " +h.slice(-2)+":" +mi.slice(-2)+":"+s.slice(-2)
			this.showTime = false
		},

		//跳下一步
		next: function() {
            let tt = !this.projectCheck()
			if(tt){
				return
			}
            this.project.projectStatus = '01'
            this.$ls.set('demo-project', this.project, 2 * 60 * 60 * 1000)
            this.$ls.set('major-demo-list', this.selectedMajors, 2 * 60 * 60 * 1000)
			this.$router.push({
                name : 'demo-2',
                params : {
                    type : 'extract'
                }
            })
		},

		//选择专业
		onSelectMajors:function(){

            this.$ls.set('major-demo-list', this.selectedMajors, 2 * 60 * 60 * 1000)
			this.$router.push({
                name: 'demoMajor',
                params:{
                    fromRouter: 'demo-1'
                }
            })
		},

		//校验数据
		projectCheck:function(){
			//项目编号
			let projectNum  = this.project.projectNum.replace(/\s+/g, "")
			if(projectNum==''){
				this.$toast("请填写项目编号！")
				return false
			}
			//项目名称
			let projectName = this.project.projectName.replace(/\s+/g, "")
			if(projectName==''){
				this.$toast("请填写项目名称！")
				return false
			}
			//采购单位
			let purchaseOrg = this.project.purchaseOrg.replace(/\s+/g, "")
			if(purchaseOrg==''){
				this.$toast("请填写采购单位！")
				return false
			}

			if(this.project.reviewDatetime==''){
				this.$toast("请填写评审时间！")
				return false
			}
			//评审地点
			let reviewSite = this.project.reviewSite.replace(/\s+/g, "")
			if(reviewSite==''){
				this.$toast("请填写评审地点！")
				return false
			}
			//包组编号
			let packageNum = this.project.packageNum.replace(/\s+/g, "")
			if(packageNum==''){
				this.$toast("请填写包组编号！")
				return false
			}
			//专家人数
			let reg1 = /^[1-9]\d*$/;
			if(!reg1.test(this.project.expertNum+'')){
				this.$toast("专家人数必须是大于0的整数！")
				return false
			}

			//是否指定了评审专业
			if(this.selectedMajors.length==0){
				this.$toast("请选择评审专业！")
				return false
			}

            let total = 0;
            let  temp = false ; // 是否有未指定人数的专业
            for (let i = 0; i < this.selectedMajors.length; i++) {
                const tt = this.selectedMajors[i];
                if(tt.num){
                    let numReg = /^[1-9][0-9]*$/
                    if(!numReg.test(tt.num)){

                        this.$toast({
                            message: tt.name  + '\n请输入正确的指定人数',
                            type: 'fail',
                            className: 'div5'
                        })
                        return false
                    }
                    tt.tempNum = tt.num
                }else{
                    temp = true
                    tt.tempNum = 0
                }
                total += parseInt(tt.tempNum)
            }
            if(total > this.project.expertNum){
                this.$toast({
                    message: '指定人数总和 超过专家指定人数\n请修改',
                    type: 'fail',
                    className: 'div7'
                })
                return false
            }

            if(!temp && total != this.project.expertNum){
                this.$toast({
                    message: '指定人数总和专家指定人数不匹配\n请修改',
                    type: 'fail',
                    className: 'div7'
                })
                return false
            }

			return true
		},

		//移除选择的评审专业
		removeChoosedMajor:function(obj,index){
			this.selectedMajors.splice(index,1)
		},

        goView(dom){
            dom.target.scrollIntoView();
        }

	},
}
</script>

<style scoped>
	#add-form>div {
		padding: 0.1rem 0.5rem;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		line-height: 1rem;
		position: relative;
	}

	#add-form>div>p {
		width: 2rem;
		font-weight: 800;
	}

	#add-form>div>i {
		pointer-events: none;
	}

	#add-form>div>span {
		display: inline-block;
		margin-left: 0.3rem;
		margin-right: 0.5rem;
	}
    #add-form>div:first-child>input{ width: calc(100% - 4rem);}
    #add-form>div:not(:first-child)>input{ width: calc(100% - 2.8rem);}

	.lh5 {
		position: absolute;
		right: 0.5rem;
		top: 0.1rem;
  }
  .greenLi{position: relative; display: inline-block; width:calc(100% - 2rem);background-color: transparent; border: none; color: #00CCB2; text-align: left;padding: 0; padding-right: 0.1rem;}
  .delBtn{height:0.65rem;padding: 0 0.35rem;line-height:0.65rem;background-color: #FF7A7A; color: white;border-radius: 0.4rem;}
  .addBtn{height:0.65rem;padding: 0 0.35rem;line-height:0.65rem;background-color: #00CCB2; color: white;border-radius: 0.4rem; margin-left: 0.3rem;}
  .del{position: absolute; top: 0.1rem;right: 0.1rem;  width: 0.45rem; height: 0.45rem;background-color: #EDEDED; content: '-'; border-radius: 50%;}
  .del::before{width: 50%;height: 1px; content: ''; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%) rotate(45deg); background-color: #A6A6A6;}
  .del::after{width: 1px;height: 50%; content: ''; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%) rotate(45deg); background-color: #A6A6A6;}
  .greenLi:not(:first-child){margin-top: 0.2rem;}
  .step {width: 2rem;  border-radius: 1rem; border: 1px solid #00CCB2; text-align: center; }
  .step::placeholder{font-size: 0.36rem;}
  .step:focus::placeholder{color: transparent;}
</style>
